
import { Options, Vue } from 'vue-class-component';
import { CargoController } from '@/app/ui/controllers/CargoController';
import { OptionsClass } from '@/domain/entities/MainApp';
import {
  formatDate,
  formatDateWithoutTime,
} from '@/app/infrastructures/misc/Utils';

@Options({
  emits: ['onSubmit'],
})
export default class ModalCompleteCargo extends Vue {
  get showModalComplete() {
    return CargoController.showModalComplete;
  }

  onReset() {
    CargoController.setShowModalCompleteCargo(false);
  }

  get formBooking() {
    return CargoController.form;
  }

  get commodityOptions(): OptionsClass[] {
    return CargoController.commodityOptions;
  }

  get commodityItem() {
    return CargoController.commodityItem;
  }

  onSelectCommodity(val: any) {
    if (val.value === this.commodityItem.value) {
      CargoController.setCommodityItem({
        value: '',
        name: '',
      });
      this.formBooking.cargoCommodityName = '';
      this.formBooking.cargoCommodityCode = '';
      this.formBooking.shcCode = '';
      this.formBooking.shcName = '';
      return;
    }
    CargoController.setCommodityItem(val);
    this.formBooking.cargoCommodityName = val.name;
    this.formBooking.cargoCommodityCode = val.value;
    this.formBooking.shcName = val.shcName;
    this.formBooking.shcCode = val.shcCode;
    const comodity = new OptionsClass({
      name: val.name,
      value: val.value,
      shcCode: val.shcCode,
      shcName: val.shcName,
    });
    this.setCargoComodity(comodity);
  }

  setCargoComodity(value: OptionsClass) {
    CargoController.setCargoComodity(value);
  }

  get disableCommodityGroup(): boolean {
    return CargoController.disableCommodityGroup;
  }

  onChangeNog(value: string) {
    CargoController.setOnChangeNog(value);
  }

  get shcValue() {
    return this.formBooking.shcCode && this.formBooking.shcName
      ? `${this.formBooking.shcCode} - ${this.formBooking.shcName}`
      : '-';
  }
  showModalConfirmationBookCargo() {
    this.$emit('onSubmit');
  }

  get nextCotHour() {
    return this.formBooking.nextFlightsData[0]?.departureHour;
  }

  get displayNextCotHour() {
    if (this.formBooking.cargoType !== 'plane') return '';
    return this.formatCotHour(
      this.formBooking.nextFlightsData[0]?.DepartureDateTime
    );
  }

  formatCotHour(dateTime: any, dayOffset = 0) {
    const date = formatDateWithoutTime(this.$moment().add(dayOffset, 'days'));
    const time = formatDate(dateTime)?.split(',')[1];
    return `${date} , ${time}`;
  }

  get hasNoCotOrNextSchedule() {
    return !this.nextCotHour && this.formBooking.cargoType === 'plane';
  }
}
